import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import DetailsHeader from "../../atoms/DetailsHeader/DetailsHeader";
import Price from "../../atoms/Price/Price";
import Availability from "../../molecules/Availability/Availability";
import Buy from "../Buy/Buy";
import { PRIMARY } from "../../../vars/vars";
import { useAppContext } from "../../../context/AppContext";
import { useConsentContext } from "../../../context/ConsentContext";
import { getAvailability } from "../../../vars/availability";

const getPrice = (part, defaultStock) =>
  part.price.prices[defaultStock].grossprice;
const isVatIncluded = (part, defaultStock) => {
  const candidate = part && part.price && part.price.prices[defaultStock];
  return candidate.grossprice !== candidate.price;
};

const isSpecialOrder = (part, defaultStock) => {
  const candidate = part.available.find(
    ({ location }) => location === defaultStock
  );
  return candidate && candidate.stockcategory !== "stockeditem";
};

const ProductDetails = ({ part, addHandler, buttonText, enquiryHandler }) => {
  const { basket } = useAppContext();
  const { cookiesConsentCtx } = useConsentContext();

  const [quantity, setQuantity] = useState(1);
  const [basketQuantity, setBasketQuantity] = useState(0);
  const partIdRef = useRef(part.id);
  const defaultStockLocation = part.defaultStockLocation;

  const { max } = getAvailability(part, quantity, basketQuantity);

  useEffect(() => {
    const found = basket?.transactionlines.find(
      (obj) => obj.item._id === partIdRef.current
    );
    setBasketQuantity(found ? found.quantity : 0);
  }, [basket]);

  return (
    <section>
      <DetailsHeader text={part.description + " " + part.name} />
      <PurchaseArea>
        <Price
          vat={getPrice(part, defaultStockLocation)}
          isVatIncluded={isVatIncluded(part, defaultStockLocation)}
          promo={part.price.prices[defaultStockLocation].promotion}
          baseprice={part.price.baseprice}
        />
        {cookiesConsentCtx.accepted ? (
          <Buy
            buttonText={buttonText}
            availability={max}
            record={part.id}
            addHandler={addHandler}
            defaultStockLocation={defaultStockLocation}
            enquiryHandler={enquiryHandler}
            max={max + basketQuantity}
            changeQuantity={(e) => setQuantity(e)}
          />
        ) : (
          <NoCookieConsent>
            In order to purchase, please refresh the page and accept the cookie
            policy
          </NoCookieConsent>
        )}
      </PurchaseArea>
      <Availability
        availability={getAvailability(
          part,
          quantity + basketQuantity,
          basketQuantity
        )}
        quantity={quantity}
        basketQuantity={basketQuantity}
      />
      {isSpecialOrder(part, defaultStockLocation) && (
        <SpecialOrder>Special Order Item</SpecialOrder>
      )}
    </section>
  );
};

export default ProductDetails;

const PurchaseArea = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  flex-direction: column;

  @media screen and (min-width: 576px) {
    flex-direction: row;
  }
`;

const NoCookieConsent = styled.div`
  border: 2px solid ${PRIMARY};
  border-radius: 10px;
  padding: 10px;
  font-size: 12px;
`;

const SpecialOrder = styled.div`
  font-family: "Open Sans", sans-serif;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #333333;
`;
