import React, { useState } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import PaymentProgress from "../../molecules/PaymentProgress/PaymentProgress";
import CheckoutHeader from "../../atoms/CheckoutHeader/CheckoutHeader";
import useBasketActions from "../../../hooks/useBasketActions";
import OrderSummary from "../../organisms/OrderSummary/OrderSummary";
import DeliverySummary from "../../organisms/DeliverySummary/DeliverySummary";
import { useHistory } from "react-router";
import PlaceOrderButton from "../../atoms/PlaceOrderButton/PlaceOrderButton";
import OrderCart from "../../organisms/OrderCart/OrderCart";
import UniversalModal from "./../../organisms/UniversalModal/UniversalModal";
import { getErrorText } from "../../../vars/functions";
import Sticky from "../../atoms/Sticky/Sticky";
import { useAppContext } from "../../../context/AppContext";

const Payment = () => {
  const { basket, reset, enhancedData } = useAppContext();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { handleOrder } = useBasketActions();
  const modalClickHandler = () => setError("");

  const placeOrder = async () => {
    setLoading(true);

    try {
      // eslint-disable-next-line no-undef
      const orderId = await handleOrder();
      window.dataLayer.push({
        ev: {
          email: enhancedData.email,
        },
        event: "purchase",
        ecommerce: {
          items: basket.transactionlines.map((line) => ({
            id: line._id,
            name: line.displayname,
            quantity: line.quantity,
            price: line.grosamount,
          })),
          affiliation: "Pedders EU",
          transaction_id: orderId,
          value: basket.grossamount,
          tax: basket.taxamount,
          shipping: basket.grossshippingcost,
          currency: basket.currency.name,
          coupon: "",
        },
      });
      history.push("/order/" + orderId);
      reset();
    } catch (error) {
      setError(getErrorText(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <PaymentWrapper>
        <div>
          <PaymentProgress />
          <CheckoutHeader text={"Payment"} />
          {basket && (
            <OrderCart
              lines={basket.transactionlines}
              currency={basket.currency.symbol}
            />
          )}
          <PlaceOrderButton loading={loading} placeOrder={placeOrder} />
        </div>
        <StyledAside>
          <Sticky>
            <OrderSummary header={"Order summary"} full />
            <DeliverySummary active />
          </Sticky>
        </StyledAside>
      </PaymentWrapper>
      {error && (
        <UniversalModal text={error} clickHandler={modalClickHandler} />
      )}
    </Container>
  );
};

export default Payment;

const PaymentWrapper = styled.div`
  padding-top: 92px;
  padding-bottom: 30px;
  display: grid;

  @media screen and (min-width: 1000px) {
    column-gap: 20px;
    grid-template-columns: 2fr 1fr;
  }
`;

const StyledAside = styled.aside`
  display: block;
  height: 100%;
`;
