import React from "react";
import styled from "styled-components";

const InStock = ({ days, msg }) => {
  return (
    <>
      <Header>
        <i
          className="fa fa-check-square"
          aria-hidden="true"
          style={{ color: "#50be19" }}
        ></i>
        <StyledStock>{days}</StyledStock>
      </Header>
      <StyledAvl>{msg}</StyledAvl>
    </>
  );
};

export default InStock;

const Header = styled.div`
  display: flex;
  font-size: 20px;
  align-items: center;
`;

const StyledStock = styled.div`
  color: #50be19;
  margin-left: 5px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
`;
const StyledAvl = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: rgb(102, 102, 102);
`;
