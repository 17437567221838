import React from "react";
import styled from "styled-components";

const InTransit = ({ days, msg }) => {
  return (
    <>
      <Header>
        <i
          className="fa fa-check-square"
          aria-hidden="true"
          style={{ color: "orange" }}
        ></i>
        <StyledStock>{msg}</StyledStock>
      </Header>

      <StyledAvl>{days}</StyledAvl>
    </>
  );
};

export default InTransit;

const Header = styled.div`
  display: flex;
  font-size: 20px;
  gap: 5px;
  align-items: center;
`;

const StyledStock = styled.div`
  color: orange;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
`;

const StyledAvl = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: rgb(102, 102, 102);
`;
